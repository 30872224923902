import Vue from "vue";

/**
 * 渲染filter header
 * @param app
 * @param params
 * @param h
 * @param compName
 * @param compProps
 * @param filterData
 * @param sortData
 * @param filterChangeCallback 筛选值改变回调函数
 * @param filterKeyEnterCallback enter键回调函数
 * @param sorterChangeCallback 排序变化时间回调函数
 * @returns header
 */
export function filterRender(
    app,
    params,
    h,
    compName,
    compProps,
    filterData,
    sortData,
    url,
    pickerDate,
    formData,
    checkedGridClassIds,
    filterChangeCallback,//如果筛选改变，则触发对应方法，可以实现筛选则查询
    filterKeyEnterCallback,
    sorterChangeCallback,
    isSort
) {
    let filterNode;
    if (isSort == null){
        isSort = true
    }
    switch (compName) {
        case 'radio':
            break
        case 'datePicker':
            filterNode = renderDatePicker(app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback)
            break
        case 'checkbox':
            break
        case 'select':
            filterNode = renderSelect(app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback)
            break
        case 'autocomplete':
            filterNode = renderAutocomplete(app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback, url, pickerDate, formData, checkedGridClassIds)
            break
        default:
            filterNode = renderInput(app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback)
            break
    }

    return [
        h('div', {
            style: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 8px',
                height: '28px'
            }
        }, [
            h('span', {}, params.column.title),
            renderSorter(app, params, h, sortData, sorterChangeCallback, filterChangeCallback,isSort)
        ]),
        h('div', {
            style: {
                width: '100%',
                boxSizing: 'border-box',
                padding: '0 5px'
            }
        }, [filterNode])
    ]
}

/**
 * 渲染排序组件
 * @param params
 * @param h
 * @param sortData
 * @param sorterChangeCallback
 * @returns {*}
 */
const renderSorter = (app, params, h, sortData, sorterChangeCallback, filterChangeCallback,isSort) => {
    if (isSort){
        //let sortType = ''
        let sortICon = 'el-icon-sort'
        const sorter = h('i',
            {
                class: sortICon
            })
        const dropdownMenu = [
            h('el-dropdown-menu', {slot: 'dropdown'}, [
                h('el-dropdown-item', {props: {command: 'asc'}}, '升序'),
                h('el-dropdown-item', {props: {command: 'desc'}}, '降序'),
                h('el-dropdown-item', {props: {command: ''}}, '重置')
            ])
        ]
        const handleSort = (value) => {
            //console.log(value)
            /*sortType = !sortType ? 'asc' : sortType === 'asc' ? 'desc' : sortType === 'desc' ? '' : ''
            sortData && (sortData[params.column.property] = sortType)*/
            //清空
            for (let key in sortData) {
                delete sortData[key];
            }
/*            if (value == ''){
                for (let key in sortData) {
                    delete sortData[key];
                }
            }*/
            sortData[params.column.property] = value
            //sorterChangeCallback && sorterChangeCallback(params)
            filterChangeCallback && filterChangeCallback(params)
        }
        return h('el-dropdown',
            {
                style: {display: 'inline-flex', cursor: 'pointer'},
                on: {
                    command: handleSort
                }
            },
            [sorter].concat(dropdownMenu)
        )
    }

}


/**
 * 渲染输入框
 * @param app
 * @param params
 * @param h
 * @param compProps
 * @param filterData
 * @param filterChangeCallback
 * @param filterKeyEnterCallback
 * @returns {*}
 */
const renderAutocomplete = (app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback, url, pickerDate, formData, checkedGridClassIds) => {
    //console.log('suggestions',suggestions)
    //console.log(h)
    /*console.log(filterData)
    console.log(params.column.property)*/
    //console.log('pickerDate',pickerDate)
    return app.$createElement('el-autocomplete', {
        ref:params.column.property+'Heard',
        props: {
            clearable: true,
            popperClass: 'myAutocomplete',
            hideLoading: true,
            triggerOnFocus:true,
            value: filterData[params.column.property],
            fetchSuggestions: (qureyStr, callback) => {
                //console.log('qureyStr',qureyStr)
                Vue.prototype.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        heardAutoCompleteName: params.column.property,
                        keyword: qureyStr,
                        pickerDate: pickerDate,
                        filterData: filterData,
                        formData: formData,
                        checkedGridClassIds: checkedGridClassIds,
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data != null && response.data.list != null && response.data.list.length > 0) {
                        //this.specificationList = response.data.page.list
                        var data = [];
                        //suggestions = [];
                        for (var i = 0; i < response.data.list.length; i++) {
                            var tem_obj = {};
                            tem_obj.value = response.data.list[i].value + "";
                            data.push(tem_obj);
                        }
                        callback(data);
                    } else {
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            ...compProps
        },
        style: {
            width: '100%'
        },
        methods:{

        },
        nativeOn: {
            keyup: function (event) {
                //console.log(event)
                if (event.key === 'Enter' && filterChangeCallback) {
                    filterChangeCallback();
                    /*event.srcElement.blur(); // 让输入框主动失焦
                    event.srcElement.style.backgroundColor = "red";*/
                    setTimeout(() => {
                        app.$refs[params.column.property+'Heard'].suggestions = []
                        event.srcElement.blur();
                    }, 500)
                }
            },
        },
        on: {
            select: function () {
                if (filterChangeCallback) {
                    filterChangeCallback();
                }
            },
            input: (value) => {
                app.$set(filterData, params.column.property, value);
            }, clear: () => {
                //console.log('clear');
                app.$delete(filterData, params.column.property);
                if (filterChangeCallback) {
                    filterChangeCallback();
                }
            },
        }

    })
}
/**
 * 渲染输入框
 * @param app
 * @param params
 * @param h
 * @param compProps
 * @param filterData
 * @param filterChangeCallback
 * @param filterKeyEnterCallback
 * @returns {*}
 */
const renderInput = (app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback) => {
    return h('vxe-input', {
        props: {
            clearable: true,
            ...compProps
        },
        on: {
            blur: (value) => {
                if (value.value === "" || value.value == null) {
                    app.$set(filterData, params.column.property, "");
                }
            }, clear: () => {
                app.$delete(filterData, params.column.property);
            },
            change: (value) => {
                filterData && app.$set(filterData, params.column.property, value.value);
                filterChangeCallback && filterChangeCallback(params);
            },
            keydown: (value) => {
                if (value.$event.key === 'Enter') {
                    filterKeyEnterCallback && filterKeyEnterCallback(params)
                }
            },
        }
    })
}

const renderDatePicker = (app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback) => {
    return h('el-date-picker', {
        props: {
            clearable: true,
            ...compProps
        },
        on: {
            blur: (value) => {
                //console.log('blur', value)
                if (value.value === "" || value.value == null) {
                    app.$set(filterData, params.column.property, "");
                }
            },
            clear: () => {
                //console.log('clear')
                app.$delete(filterData, params.column.property);
            },
            change: (value) => {
                filterData && app.$set(filterData, params.column.property, value.value);
                filterChangeCallback && filterChangeCallback(params);
                //console.log('change', value)
            },
            keydown: (value) => {
                if (value.$event.key === 'Enter') {
                    filterKeyEnterCallback && filterKeyEnterCallback(params)
                }
            },
        }
    })
}

const renderSelect = (app, params, h, compProps, filterData, filterChangeCallback, filterKeyEnterCallback) => {
    const OptionNodes = []
    if (compProps && compProps.options) {
        compProps.options.forEach((option) => {
            OptionNodes.push(
                h('el-option', {
                    props: {
                        ...option
                    }
                })
            )
        })
    }
    return h('el-select', {
        props: {
            filterable: true,
            value: filterData[params.column.property],
            ...compProps
        },
        style: {
            width: '100%'
        },
        on: {
            change: (value) => {
                filterData && app.$set(filterData, params.column.property, value)
                filterChangeCallback && filterChangeCallback(params)
            },
            keydown: (value) => {
                if (value.$event.key === 'Enter') {
                    filterKeyEnterCallback && filterKeyEnterCallback(params)
                }
            }, clear: () => {
                app.$delete(filterData, params.column.property);
            },
        }
    }, OptionNodes)
}
