<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial ">
            <el-form ref="formData" :model="formData" label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,短信数据不是实时)':''}}当前：汇款短信</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 320px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        :clearable="false"
                                        size="mini"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :lg="7">
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round size="mini"
                                           @click="searchSmsList">搜索查询
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " @click="deleteEvent"
                                           type="primary" size="mini">删除
                                </el-button>
                                <el-button type="primary" @click="payOrder" size="mini">下载apk</el-button>
                                <el-dialog
                                        width="230px"
                                        :title="formData.payment"
                                        @close="closeCode"
                                        :visible.sync="innerVisible"
                                        append-to-body>
                                    <div class="paycode">
                                        <!-- 放置二维码的容器,需要给一个ref -->
                                        <div id="qrcode" ref="qrcode"></div>
                                    </div>
                                </el-dialog>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                align="center"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-hover-row
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :row-class-name="tableRowClassName"
                                :checkbox-config="{range:true}"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
    </div>
</template>
<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    import QRCode from 'qrcodejs2'
    export default {

        data() {
            let end = new Date();
            let start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 7);
            //如果是一月份，年则向上查
            var vue = this;//重要，vue在data数据调用this
            return {
                remvoeIds: [],
                pickerOptions: {
                    onPick(time) {
                        // 这里可以写执行之后的逻辑 用户选择一次时间范围会触发两次
                        //当第一时间选中才设置禁用
                        if (time.minDate && !time.maxDate) {
                            //console.log('第一次执行设置值');
                            vue.timeOptionRange = time.minDate;
                       }
                        if (time.maxDate) {
                            vue.timeOptionRange = null;
                       }
                        //console.log("onPick: " + time);
                        //console.log(time);
                   },
                    disabledDate: (time) => {
                        let timeOptionRange = this.timeOptionRange;
                        if (timeOptionRange) {
                            return time.getTime() < new Date(timeOptionRange).setMonth(0) || time.getTime() > new Date(timeOptionRange).setMonth(11) || time.getTime() > new Date().setMonth(new Date().getMonth())
                       } else {
                            return time.getTime() > new Date().setMonth(new Date().getMonth())
                       }
                        //return time.getTime() > new Date().setMonth(new Date().getMonth() - 1)//大于等于今年今月不能筛选
                        // return time.getTime() < (new Date((new Date().getFullYear()).toString())).getTime() || time.getTime() > new Date().setMonth(new Date().getMonth() - 1)
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   },{
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },]
               },
                payment: '财务短信转发apk',
                innerVisible: false,
                tableDataMain: [],
                tableColumn: [
                    {type: 'checkbox', width: 50},
                    {field:'create_date', width: 170, title: '日期'},
                    {
                        field:'money',width: 120,  title: '汇款金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/sms/searchHeard', this.pickerDate,null,null,this.searchSmsList)
                       }
                   },
                    {
                        field:'p_account', title: '收款名称及银行账户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/sms/searchHeard', this.pickerDate,null,null,this.searchSmsList)
                       }
                   },
                    {
                        field:'company_user_name', title: '汇款人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/sms/searchHeard', this.pickerDate,null,null,this.searchSmsList)
                       }
                   },
                    {
                        field:'c_name', title: '对应公司', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/sms/searchHeard', this.pickerDate,null,null,this.searchSmsList)
                       }
                   },

                    {
                        field:'b_name', title: '银行名字', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/sms/searchHeard', this.pickerDate,null,null,this.searchSmsList)
                       }
                   },
                    {
                        field:'state', title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/sms/searchHeard', this.pickerDate,null,null,this.searchSmsList)
                       },
                   },
                    {
                        field:'telete_number', title: '来短信号码', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/sms/searchHeard', this.pickerDate,null,null,this.searchSmsList)
                       },
                   }
                ],
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                filterData: {},
                sortData: {},
                pickerDate: [start, end],//查询年限 月份
                formData: {
                    companyUserName: '',
                    payment: '',
                    money: '',
                    payAccount: '',
                    bankName: '',
               },
                activeName: 'first',
                checked: false,
                regionList: [],
           }
       },
        methods: {
            // 生成二维码
            crateQrcode() {
                this.qr = new QRCode('qrcode', {
                    width: 150,
                    height: 150, // 高度
                    text: this.qrcode // 二维码内容
                    // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                    // background: '#f0f'
                    // foreground: '#ff0'
               })
                //console.log(this.qrcode)
           },
            // 展示二维码
            payOrder() {
                this.innerVisible = true
                // 二维码内容,一般是由后台返回的跳转链接,这里是写死的一个链接
                this.qrcode = 'https://www.mnerp.com/apk/app-mnerp.apk'
                // 使用$nextTick确保数据渲染
                this.$nextTick(() => {
                    this.crateQrcode()
               })
           },
            // 关闭弹框,清除已经生成的二维码
            closeCode() {
                this.$refs.qrcode.innerHTML = ''
           },

            deleteEvent() {
                const $grid = this.$refs.xGrid
                const selectRecords = $grid.getCheckboxRecords();
                this.remvoeIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.remvoeIds.push(selectRecords[i].id)
               }
                if (selectRecords.length > 0) {
                    this.$XModal.confirm('您确定要删除该数据?').then(type => {
                        if (type === 'confirm') {
                            //执行删除请求
                            this.$axios({
                                method: 'post',
                                url: '/admin/sms/deleteBatchVue',
                                params: {
                                    remvoeIds: this.remvoeIds
                               }
                           }).then((response) => {         //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量删除成功',
                                        type: 'success'
                                   });
                                    this.getSmsList();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   })
               } else {
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }
           },
            roleFetchSuggestions(queryString, cb) {
                var restaurants = this.restaurants
                var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    cb(results)
               }, 1000)
           },
            editMethod({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            onSubmit() {
                //console.log('submit!');
           },//查询accountList
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getSmsList();
           },
            searchSmsList() {
                this.tablePage.currentPage = 1
                this.getSmsList();
           },
            getSmsList() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'POST',
                    url: '/admin/sms/getSmsList',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        pickerDate: this.pickerDate,
                        sortData: this.sortData,
                        filterData: this.filterData,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.list
                        this.tablePage.pageSize = response.data.pageSize
                        this.tablePage.total = response.data.totalRow
                        this.tablePage.currentPage = response.data.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           }
       }
        ,
        created() {
            this.loading = true
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                //this.$refs.xGrid.connect("123")
           })
            this.getSmsList();
       }
   }
    ;


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }
</style>
